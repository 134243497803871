import React from "react";
import "./styles.css";
export default function index() {
  return (
    <div className="about">
      <div className="about-container">
        <div className="about-container-header">About Us</div>
        <div className="about-container-body">
          <p>
            Quaerat ullam cumque magni sed. Non possimus iste laudantium
            repudiandae repellendus sunt velit impedit. Et possimus enim
            pariatur. Voluptas impedit qui ut nesciunt temporibus omnis et qui.
            <br />
            <br />
            Reprehenderit tempore eos autem iste. Nesciunt aliquid esse in eum.
            Est voluptas iusto quidem reprehenderit molestiae deserunt minus
            dolorem. Itaque voluptas nulla excepturi culpa quo est neque velit.
            Autem beatae nam amet. Et sit aspernatur officia rem minima ut.
            <br />
            <br />
            Dolorem laudantium blanditiis sit nemo fugit odio dolore dolorum.
            Illum et nam qui consequatur est debitis et. Asperiores amet est et
            pariatur excepturi sequi harum rerum. Quisquam quaerat nobis autem
            ut impedit debitis. Quis est quisquam aut vitae. Quo perferendis
            maxime voluptate quo possimus. Ab rerum reiciendis et deleniti
            reprehenderit sint. Rerum est earum provident saepe quo dignissimos.
            <br />
            <br />
            Voluptates omnis natus illum magnam harum eum. Dolor dicta rerum ut
            hic sint nulla. Quo eum quidem ad harum beatae nostrum. Officia in
            quis minima totam maxime. Provident sint excepturi doloribus
            repudiandae repellendus quasi.
          </p>
        </div>
      </div>
    </div>
  );
}
